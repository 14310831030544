import React from "react";

function Footer() {
    return (
        <div className="footer">
            <img src="https://i.imgur.com/yt7qhEs.png" alt="Charlie Chase Productions Logo"/>
        </div>
    )
}

export default Footer;